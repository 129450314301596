export class CommentManager {
  _id: string;
  existsVersionId: string;
  existsRecordId: string;
  code: number;
  libraryCode: string;
  lab: string;
  department: string;
  investigationType: string;
  investigationCode: string;
  investigationName: string;
  species: string;
  sex: string;
  instType: string;
  commentsType: string;
  commentsFor: string;
  ageFrom: number;
  ageFromUnit: string;
  ageTo: number;
  ageToUnit: string;
  low: string;
  high: string;
  alpha: number;
  companyCode: string;
  status: string;
  enteredBy: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  versions: number;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;
  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.existsVersionId = rawData.existsVersionId;
    this.existsRecordId = rawData.existsRecordId;
    this.code = rawData.code;
    this.libraryCode = rawData.libraryCode;
    this.lab = rawData.lab;
    this.department = rawData.department;
    this.investigationType = rawData.investigationType;
    this.investigationCode = rawData.investigationCode;
    this.investigationName = rawData.investigationName;
    this.species = rawData.species;
    this.sex = rawData.sex;
    this.instType = rawData.instType;
    this.commentsType = rawData.commentsType;
    this.commentsFor = rawData.commentsFor;
    this.ageFrom = rawData.ageFrom;
    this.ageFromUnit = rawData.ageFromUnit;
    this.ageTo = rawData.ageTo;
    this.ageToUnit = rawData.ageToUnit;
    this.low = rawData.low;
    this.high = rawData.high;
    this.alpha = rawData.alpha;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.enteredBy = rawData.enteredBy;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.versions = rawData.versions;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
