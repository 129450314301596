export class MasterPanel {
  _id: string;
  existsVersionId: string;
  existsRecordId: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  version: number;
  enteredBy: string;
  rLab: string;
  pLab: string;
  department: string;
  section: Record<string, any>;
  panelCode: string;
  panelName: string;
  panelMethodCode: string;
  panelMethodName: string;
  description: string;
  shortName: string;
  bill: boolean;
  price: number;
  schedule: string;
  autoRelease: boolean;
  isSaleable: boolean;
  holdOOS: boolean;
  validationLevel: number;
  confidential: boolean;
  urgent: boolean;
  reportGroup: number;
  reportOrder: number;
  ageAction: string;
  hiAge: string;
  loAge: string;
  sexAction: string;
  sex: string;
  actionMessage: string;
  ageSexAction: boolean;
  processing: string;
  category: string;
  suffix: string;
  serviceType: string;
  panelType: string;
  repitation: boolean;
  tubeGroup: string;
  printLabel: boolean;
  labelInstruction: string;
  pageBreak: boolean;
  method: boolean;
  panelMethod: string;
  workflow: string;
  cumulative: boolean;
  reportTemplate: string;
  reportTemplateOrder: number;
  sampleType: string;
  specalInstructions: string;
  interpretation: string;
  internalComments: string;
  externalComments: string;
  panelBottomMarker: object;
  panelRightMarker: string;
  externalPanelCode: string;
  companyCode: string;
  status: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.existsVersionId = rawData.existsVersionId;
    this.existsRecordId = rawData.existsRecordId;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.version = rawData.version;
    this.enteredBy = rawData.enteredBy;
    this.rLab = rawData.rLab;
    this.pLab = rawData.pLab;
    this.department = rawData.department;
    this.section = rawData.section;
    this.panelCode = rawData.panelCode;
    this.panelName = rawData.panelName;
    this.panelMethodCode = rawData.panelMethodCode;
    this.panelMethodName = rawData.panelMethodName;
    this.description = rawData.description;
    this.shortName = rawData.shortName;
    this.bill = rawData.bill;
    this.price = rawData.price;
    this.schedule = rawData.schedule;
    this.autoRelease = rawData.autoRelease;
    this.holdOOS = rawData.holdOOS;
    this.validationLevel = rawData.validationLevel;
    this.confidential = rawData.confidential;
    this.isSaleable = rawData.isSaleable;
    this.urgent = rawData.urgent;
    this.reportGroup = rawData.reportGroup;
    this.reportOrder = rawData.reportOrder;
    this.ageAction = rawData.ageAction;
    this.hiAge = rawData.hiAge;
    this.loAge = rawData.loAge;
    this.sexAction = rawData.sexAction;
    this.sex = rawData.sex;
    this.actionMessage = rawData.actionMessage;
    this.ageSexAction = rawData.ageSexAction;
    this.method = rawData.method;
    this.panelMethod = rawData.panelMethod;
    this.processing = rawData.processing;
    this.category = rawData.category;
    this.suffix = rawData.suffix;
    this.serviceType = rawData.serviceType;
    this.panelType = rawData.panelType;
    this.repitation = rawData.repitation;
    this.tubeGroup = rawData.tubeGroup;
    this.printLabel = rawData.printLabel;
    this.labelInstruction = rawData.labelInstruction;
    this.pageBreak = rawData.pageBreak;
    this.workflow = rawData.workflow;
    this.cumulative = rawData.cumulative;
    this.reportTemplate = rawData.reportTemplate;
    this.reportTemplateOrder = rawData.reportTemplateOrder;
    this.sampleType = rawData.sampleType;
    this.specalInstructions = rawData.specalInstructions;
    this.internalComments = rawData.internalComments;
    this.externalComments = rawData.externalComments;
    this.panelBottomMarker = rawData.panelBottomMarker;
    this.panelRightMarker = rawData.panelRightMarker;
    this.externalPanelCode = rawData.externalPanelCode;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.serviceType = rawData.serviceType;
    this.interpretation = rawData.interpretation;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class MasterPanelActivity {
  disablePanelName: boolean;

  constructor(rawData: { [key in string]: any }) {
    this.disablePanelName = rawData.disablePanelName;
  }
}
