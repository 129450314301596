export class Payment {
  _id: string;
  pId: number;
  labId: number;
  rLab: string;
  invoiceAC: number;
  customerName: string;
  customerGroup: string;
  acClass: string;
  acType: string;
  discountCharges: string;
  invoiceDate: Date;
  grossAmount: number;
  netAmount: number;
  discountAmount: number;
  discountPer: number;
  miscellaneousCharges: number;
  allMiscCharges: Array<any>;
  amountPayable: number;
  receivedAmount: number;
  totalReceivedAmount: number;
  balance: number;
  modeOfPayment: string;
  paymentRemark: string;
  actionDate: Date;
  status: string;
  enteredBy: string;
  patientOrderId: string;
  transactionHeaderId: string;
  visitId: number;
  dateOfEntry: Date;
  lastUpdated: Date;

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.pId = rawData.pId;
    this.labId = rawData.labId;
    this.rLab = rawData.rLab;
    this.invoiceAC = rawData.invoiceAC;
    this.customerName = rawData.customerName;
    this.customerGroup = rawData.customerGroup;
    this.acClass = rawData.acClass;
    this.acType = rawData.acType;
    this.discountCharges = rawData.discountCharges;
    this.invoiceDate = rawData.invoiceAC;
    this.grossAmount = rawData.grossAmount;
    this.netAmount = rawData.netAmount;
    this.discountAmount = rawData.discountAmount;
    this.discountPer = rawData.discountPer;
    this.miscellaneousCharges = rawData.miscellaneousCharges;
    this.allMiscCharges = rawData.allMiscCharges;
    this.amountPayable = rawData.amountPayable;
    this.receivedAmount = rawData.receivedAmount;
    this.totalReceivedAmount = rawData.totalReceivedAmount;
    this.balance = rawData.balance;
    this.modeOfPayment = rawData.modeOfPayment;
    this.paymentRemark = rawData.paymentRemark;
    this.actionDate = rawData.actionDate;
    this.status = rawData.status;
    this.enteredBy = rawData.enteredBy;
    this.patientOrderId = rawData.patientOrderId;
    this.transactionHeaderId = rawData.transactionHeaderId;
    this.visitId = rawData.visitId;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
